import { Suspense } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  ModerationMerchants,
  ModerationItems,
  Promotion,
  Login,
  Page404,
} from "../src/router/router";
import { getToken } from "./utils/Token";
import { PagesSpinner } from "./components/Loaders";
import "./App.css";
import "antd/dist/reset.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function App() {
  const token = getToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && pathname === "/") {
      navigate("/layout");
    }
    if (!token) {
      navigate("/");
    }
  }, [token, pathname, navigate]);
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <PagesSpinner />
                </div>
              }
            >
              <Login />
            </Suspense>
          }
        />
        <Route
          path="layout"
          element={
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <PagesSpinner />
                </div>
              }
            >
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <ModerationMerchants />
              </Suspense>
            }
          />

          <Route
            path="items"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <ModerationItems />
              </Suspense>
            }
          />

          <Route
            path="promotion"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Promotion />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={
              <Suspense
                fallback={
                  <div className="flex justify-center">
                    <PagesSpinner />
                  </div>
                }
              >
                <Page404 />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
