import { lazy } from "react";

export const Login = lazy(() => import("../container/LoginPage"));
export const Layout = lazy(() => import("../container/MainPage"));
export const ModerationMerchants = lazy(() =>
  import("../container/ModerationMerchants")
);
export const ModerationItems = lazy(() =>
  import("../container/ModerationItems")
);
export const Promotion = lazy(() => import("../container/Promotion"));
export const Page404 = lazy(() => import("../container/NotFoundPage"));
