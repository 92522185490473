import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosBaseUrl, axiosBaseRequest } from "../utils/axiosBaseUrl";

const initialState = {
  units: [],
  itemsForOption: null,
  loading: false,
  itemsNotActivated: null,
  itemsStatus: false,
  publishOffcet: 1,
  unPublishOffcet: 1,
  status: "",
  error: "",
};

export const getUnits = createAsyncThunk(
  "items/getUnits",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/units`;
      const { data } = await axiosBaseUrl.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getItemsForOptions = createAsyncThunk(
  "items/getItemsForOptions",
  async (merchantId, { rejectWithValue }) => {
    try {
      let uri = `items/marketplace/all?offset=0&limit=1000&merchant_id=${merchantId}`;
      const { data } = await axiosBaseUrl.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getItems = createAsyncThunk(
  "moderators/getItems",
  async ({ query, offset, status }, { rejectWithValue }) => {
    try {
      let uri = `items/moderator/?published=${status}`;
      if (offset !== undefined) {
        uri += `&offset=${offset}&limit=6`;
      }
      if (query !== undefined && query !== "") {
        uri += `&q=${query}`;
      }
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const activateItem = createAsyncThunk(
  "moderators/activateItem",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    let uri = `items/moderator`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(
        getItems({
          offset:
            (getState().items.itemsStatus
              ? getState().items.offcet - 1
              : getState().items.unPublishOffcet - 1) * 6,
          query: undefined,
          status: getState().items.itemsStatus,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setItemsStatus: (state, action) => {
      state.itemsStatus = action.payload;
    },
    setOffcet: (state, action) => {
      state.publishOffcet = action.payload;
    },
    setUnPublishOffcet: (state, action) => {
      state.unPublishOffcet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnits.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.status = "Success";
      state.units = action.payload;
    });
    builder.addCase(getUnits.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(getItemsForOptions.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getItemsForOptions.fulfilled, (state, action) => {
      state.status = "Success";
      state.itemsForOption = action.payload.items;
    });
    builder.addCase(getItemsForOptions.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });
    builder.addCase(getItems.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.loading = false;
      state.itemsNotActivated = action.payload;
    });
    builder.addCase(getItems.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(activateItem.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(activateItem.fulfilled, (state, action) => {
      state.loading = false;
      notification["success"]({
        description: state.itemsStatus
          ? "Товар снять с публикации"
          : "Товар опубликован на маркетплэйс!",
      });
    });
    builder.addCase(activateItem.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      notification["error"]({
        description: "Операция не выполнена!",
      });
    });
  },
});

export const { setItemsStatus, setOffcet, setUnPublishOffcet } =
  itemSlice.actions;

export default itemSlice.reducer;
