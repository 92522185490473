import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosBaseUrl } from "../utils/axiosBaseUrl";

export const getCategories = createAsyncThunk(
  "commons/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/categories`;

      const { data } = await axiosBaseUrl.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getBrands = createAsyncThunk(
  "commons/getBrands",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/brands`;

      const { data } = await axiosBaseUrl.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.status = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    brands: [],
  },
  reducers: {},
  extraReducers: {
    [getCategories.pending]: setLoading,
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    [getCategories.rejected]: setError,

    [getBrands.pending]: setLoading,
    [getBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload;
    },
    [getBrands.rejected]: setError,
  },
});

export default categoriesSlice.reducer;
