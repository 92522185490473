import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosBaseUrl } from "../utils/axiosBaseUrl";

export const getAreas = createAsyncThunk(
  "commons/getAreas",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `commons/areas`;

      const { data } = await axiosBaseUrl.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const areasSlice = createSlice({
  name: "areas",
  initialState: {
    areas: [],
  },
  reducers: {},
  extraReducers: {
    [getAreas.pending]: setLoading,
    [getAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.areas = action.payload;
    },
    [getAreas.rejected]: setError,
  },
});

export default areasSlice.reducer;
