import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosBaseUrl, axiosBaseRequest } from "../utils/axiosBaseUrl";
import jwt_decode from "jwt-decode";
import { saveToken, destroyToken, getToken } from "../utils/Token";

const initialState = {
  registerStatus: "",
  registerError: "",
  loginStatus: "",
  loginError: "",
  status: "",
  loading: false,
};

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `forgot-password`;

      const { data } = await axiosBaseUrl.post(uri, body);
      navigate("/");
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const restorePassword = createAsyncThunk(
  "users/restorePassword",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `users/reset-password`;

      const { data } = await axiosBaseRequest.patch(uri, body);
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const ownRefreshToken = createAsyncThunk(
  "refreshToken",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `token_refresh`;

      const { data } = await axiosBaseUrl.post(uri, body);
      saveToken(data.access_token, data.refresh_token);
      console.log(jwt_decode(data.access_token));
      console.log(jwt_decode(data.refresh_token));
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const changeUserInfo = createAsyncThunk(
  "users/changeUserInfo",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `users/`;

      const { data } = await axiosBaseRequest.patch(uri, body);
      dispatch(
        ownRefreshToken({
          refresh_token: localStorage.getItem("refresh_token"),
        })
      );
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `login/`;

      const { data } = await axiosBaseUrl.post(uri, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      saveToken(data.access_token, data.refresh_token);

      getToken().role_id === 5 && !getToken().disabled
        ? navigate("/layout")
        : destroyToken();

      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "users/logout",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `logout`;

      const { data } = await axiosBaseUrl.post(uri);
      destroyToken();
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
      // return { ...state, loginStatus: "pending" };
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loginError = action.payload;
      state.loading = false;
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      state.status = "pending";
      // return { ...state, status: "pending" };
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(restorePassword.pending, (state, action) => {
      state.status = "pending";
      // return { ...state, status: "pending" };
    });
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(changeUserInfo.pending, (state, action) => {
      state.status = "pending";
      // return { ...state, status: "pending" };
    });
    builder.addCase(changeUserInfo.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(changeUserInfo.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });

    builder.addCase(logout.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.status = "Success";
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
