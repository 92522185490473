import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosBaseRequest } from "../utils/axiosBaseUrl";

const initialState = {
  merchantsNotActivated: null,
  activeMerchants: null,
  offcet: 1,
  notActivatedOffcet: 1,
  orders: null,
  userInfo: {},
  loading: false,
  promotionObj: null,
  promotionBanner: null,
  promotions: null,
  fullPromotions: null,
  promotionModalVisible: false,
  deletePromotionModal: false,
  editPromotionModal: false,
  merchantsStatus: false,
  status: "",
  error: "",
};

export const getUserInfo = createAsyncThunk(
  "users/getUserInfo",
  async (_, { rejectWithValue }) => {
    let uri = `users/me`;
    try {
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMerchants = createAsyncThunk(
  "moderators/getMerchants",
  async ({ query, offset, status }, { rejectWithValue }) => {
    try {
      let uri = `users/moderator/merchants?published=${status}`;
      if (offset !== undefined) {
        uri += `&offset=${offset}&limit=6`;
      }
      if (query !== undefined && query !== "") {
        uri += `&q=${query}`;
      }

      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getActiveMerchants = createAsyncThunk(
  "moderators/getActiveMerchants",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `users/moderator/merchants?published=true`;

      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getOnePublishItem = createAsyncThunk(
  "moderators/getOneItem",
  async (itemId, { rejectWithValue }) => {
    try {
      let uri = `items/moderator/${itemId}`;
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrders = createAsyncThunk(
  "moderators/getOrders",
  async ({ query, offset }, { rejectWithValue }) => {
    try {
      let uri = `payment/moderator/orders`;
      if (offset !== undefined) {
        uri += `?offset=${offset}&limit=6`;
      }
      if (query !== undefined && query !== "") {
        uri += `?q=${query}`;
      }
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const orderStatus = createAsyncThunk(
  "moderators/orderStatus",
  async ({ id, status }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `payment/order/status?order_uuid=${id}&order_status=${status}`;
      const { data } = await axiosBaseRequest.post(uri);
      dispatch(getOrders({ offset: 0, query: undefined }));

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const activateMerchant = createAsyncThunk(
  "moderators/activateMerchant",
  async ({ body, id }, { rejectWithValue, dispatch, getState }) => {
    let uri = `users/moderator`;

    try {
      const { data } = await axiosBaseRequest.patch(`${uri}/${id}`, body);

      dispatch(
        getMerchants({
          offset:
            (getState().manager.merchantsStatus
              ? getState().manager.offcet - 1
              : getState().manager.notActivatedOffcet - 1) * 6,
          query: undefined,
          status: getState().manager.merchantsStatus,
        })
      );
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const publicPromotion = createAsyncThunk(
  "moderators/publicPromotion",
  async ({ body, file }, { rejectWithValue, dispatch }) => {
    console.log(file);

    try {
      let uri = `promotions/banner`;
      const { data } = await axiosBaseRequest.post(uri, body);
      if (file) {
        dispatch(addPromotionBanner({ body: file, id: data.banner_id }));
      } else console.log("not file");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const addPromotionBanner = createAsyncThunk(
  "moderators/addPromotionBanner",
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    try {
      let uri = `promotions/banner/image/?banner_id=${id}`;
      const { data } = await axiosBaseRequest.post(uri, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getPromotions());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getPromotions = createAsyncThunk(
  "moderators/getPromotions",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `promotions/banner/`;
      const { data } = await axiosBaseRequest.get(uri);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const deletePromotion = createAsyncThunk(
  "moderators/deletePromotion",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `promotions/banner/${id}`;
      const { data } = await axiosBaseRequest.delete(uri);
      dispatch(getPromotions());

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const editPromotion = createAsyncThunk(
  "moderators/editPromotion",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let uri = `promotions/banner`;
      const { data } = await axiosBaseRequest.patch(uri, body);
      dispatch(getPromotions());

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    setPromotionObj: (state, action) => {
      state.promotionObj = action.payload;
    },

    setPromotionBanner: (state, action) => {
      state.promotionBanner = action.payload;
    },

    setPromotionModalVisible: (state, action) => {
      state.promotionModalVisible = action.payload;
    },
    setDeletePromotionModal: (state, action) => {
      state.deletePromotionModal = action.payload;
    },

    setEditPromotionModal: (state, action) => {
      state.editPromotionModal = action.payload;
    },
    setMerchantsStatus: (state, action) => {
      state.merchantsStatus = action.payload;
    },
    setOffcet: (state, action) => {
      state.offcet = action.payload;
    },
    setNotActivatedOffcet: (state, action) => {
      state.notActivatedOffcet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getMerchants.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getMerchants.fulfilled, (state, action) => {
      state.loading = false;
      state.merchantsNotActivated = action.payload;
    });
    builder.addCase(getMerchants.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getActiveMerchants.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getActiveMerchants.fulfilled, (state, action) => {
      state.loading = false;
      state.activeMerchants = action.payload;
    });
    builder.addCase(getActiveMerchants.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(getOrders.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(orderStatus.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(orderStatus.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(orderStatus.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(activateMerchant.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(activateMerchant.fulfilled, (state, action) => {
      state.loading = false;
      notification["success"]({
        description: state.merchantsStatus
          ? "Мерчант деактивирован"
          : "Мерчант активирован!",
      });
    });
    builder.addCase(activateMerchant.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      notification["error"]({
        description: "Операция не выполнена!",
      });
    });

    builder.addCase(publicPromotion.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(publicPromotion.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(publicPromotion.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      notification["error"]({
        description: "Операция не выполнена!",
      });
    });

    builder.addCase(addPromotionBanner.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(addPromotionBanner.fulfilled, (state, action) => {
      state.loading = false;
      notification["success"]({
        description: "Баннер создан!",
      });
      state.promotionObj = null;
      state.promotionBanner = null;
      state.promotionModalVisible = false;
    });
    builder.addCase(addPromotionBanner.rejected, (state, action) => {
      state.status = "rejected";
      state.loading = false;
      notification["error"]({
        description: "Фото не загрусился!",
      });
    });

    builder.addCase(getPromotions.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });

    builder.addCase(getPromotions.fulfilled, (state, action) => {
      state.loading = false;
      state.promotions = action.payload;
      state.fullPromotions = action.payload;
    });

    builder.addCase(getPromotions.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deletePromotion.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });

    builder.addCase(deletePromotion.fulfilled, (state, action) => {
      state.loading = false;
      state.deletePromotionModal = false;
      notification["success"]({
        description: "Реклама удалена!",
      });
    });

    builder.addCase(deletePromotion.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(editPromotion.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });

    builder.addCase(editPromotion.fulfilled, (state, action) => {
      state.loading = false;
      state.editPromotionModal = false;
      notification["success"]({
        description: "Реклама изменена!",
      });
    });

    builder.addCase(editPromotion.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getOnePublishItem.pending, (state, action) => {
      state.status = "pending";
      state.loading = true;
    });

    builder.addCase(getOnePublishItem.fulfilled, (state, action) => {
      state.loading = false;

      state.fullPromotions = state.fullPromotions.map((elem) => {
        if (elem.item_id === action.payload.item_id) {
          return { ...elem, item_title: action.payload.title.ru };
        }
        return elem;
      });
    });

    builder.addCase(getOnePublishItem.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setPromotionBanner,
  setPromotionObj,
  setPromotionModalVisible,
  setDeletePromotionModal,
  setEditPromotionModal,
  setMerchantsStatus,
  setOffcet,
  setNotActivatedOffcet,
} = managerSlice.actions;

export default managerSlice.reducer;
