import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Registration";
import managerReducer from "./Managers";
import areasReducer from "./Areas";
import categoriesReducer from "./Categories";
import itemsReducer from "./Items";

const rootReducer = combineReducers({
  auth: authReducer,
  manager: managerReducer,
  areas: areasReducer,
  categories: categoriesReducer,
  items: itemsReducer,
});

export default rootReducer;
